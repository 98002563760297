import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Box from "@material-ui/core/Box";
//Screens
import Detalles from "./ViajeDetalles";
import PrimerPaciente from "./PrimerPaciente";
import SegundoPaciente from "./SegundoPaciente";
import Confirmacion from "./Confirmacion";
import ViajeDetalles from "./ViajeDetalles";
import LoadingServicio from "./LoadingServicio";
import moment, { add } from "moment";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import Swal from "sweetalert2";
import { BASE_URL } from "../../../utils/index";
import Exitoso from "../Exitoso";
import Error from "../Error";

export default function Quimioterapia() {
  const defaultProps = {
    bgcolor: "background.paper",
    m: 1,
    border: 1,
  };

  //request data

  useEffect(() => {
    const fetchMyAPI = async () => {
      let fechaInicial = new Date();
      let fechaPoner = moment(fechaInicial).format("YYYY/MM/DD");

      let email2 = localStorage.getItem("email");
      let password2 = localStorage.getItem("password");
      let idCliente = localStorage.getItem("idCliente");
      let perfil = localStorage.getItem("perfil");
      let csrfToken = localStorage.getItem("csrfToken");
      console.log(csrfToken);

      let response = await fetch(`${BASE_URL}/servicios`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": csrfToken, // Adjuntar el token CSRF como encabezado
        },
        body: JSON.stringify({
          email: email2,
          password: password2,
        }),
      });
      let response2 = await response.json();

      let pueblosObj = [];
      response2.pueblos.map((e, i) => {
        var obj = { title: e.pueblo };
        pueblosObj.push(obj);
      });

      let destinosObj = [];
      response2.destinos.map((e, i) => {
        var obj = { title: e.destino };
        destinosObj.push(obj);
      });

      let centrosAssistenciaObj = [];
      response2.centrosAsistencia.map((e, i) => {
        var obj = { title: e.label };
        centrosAssistenciaObj.push(obj);
      });
      let clientesObj = [];
      response2.clientes.map((e, i) => {
        var obj = { id: e.id, nombre: e.nombre };
        clientesObj.push(obj);
      });
      let pacientesObj = [];
      response2.pacientes.map((e, i) => {
        var obj = {
          nombre: e.nombre,
          pueblo: e.pueblo,
          direccion: e.direccion,
          numTelefono: e.numTelefono,
          nass: e.nass,
          numTelefono2: e.numTelefono2,
        };
        pacientesObj.push(obj);
      });

      setStateI({
        ...stateI,
        todosDestinos: destinosObj,
        pueblos: pueblosObj,
        turnosLLeida: response2.cap,
        turnosArnau: response2.arnau,
        turnosIgualada: response2.igualada,
        email: email2,
        password: password2,
        idCliente: idCliente,
        fechaCompletaEnviar: fechaPoner,
        todosCentros: centrosAssistenciaObj,
        clientes: clientesObj,
        pacientes: pacientesObj,
        perfilLog: perfil,
        idClienteSol: perfil == 5 ? null : "",
      });
    };

    fetchMyAPI();
  }, []);

  //Estilos
  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: "relative",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  //Steps
  const steps = [
    "Información del viaje",
    "Primer paciente",
    "Segundo paciente *",
    "Confirma tu viaje",
  ];

  //step counter
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <ViajeDetalles
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            handleData={handleData}
            handleVehiculo={handleVehiculo}
            handleDestino={handleDestinoViaje}
            handleConfirm={handleConfirm}
            handleCentros={handleCentros}
            activeStep={activeStep}
            handleClientes={perfilLog == 5 ? handleClientes : null}
            handleUrgente={handleUrgente}
            handlePeritoneal={handlePeritoneal}
          />
        );

      case 1:
        return (
          <PrimerPaciente
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            handleData={handleData}
            handlePueblo1={handlePueblo1}
            activeStep={activeStep}
            handlePacientes={handlePacientes1}
          />
        );

      case 2:
        return (
          <SegundoPaciente
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            handleData={handleData}
            handlePueblo2={handlePueblo2}
            activeStep={activeStep}
            handlePacientes={handlePacientes2}
          />
        );

      case 3:
        return (
          <Confirmacion
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            activeStep={activeStep}
          />
        );

      case 4:
        return <LoadingServicio values={values} />;

      default:
        break;
    }
  }

  //Estado global
  const [stateI, setStateI] = useState({
    step: 0,
    tipo: "TURISME",
    destino: {},
    direccionHospital: "",
    fecha: new Date(),
    hora: new Date(),
    fechaCompletaEnviar: "",
    fechaString: "",
    horaString: "",
    fechaMostrar: "Aún no seleccionas fecha",

    primerPaciente: "",
    puebloPrimerPaciente: {},
    telfPrimerPaciente: "",
    telfPrimerPaciente2: "",
    direccionPrimerPaciente: "",
    NASS1: "",

    segundoPaciente: "",
    puebloSegundoPaciente: {},
    telfSegundoPaciente: "",
    telfSegundoPaciente2: "",
    direccionSegundoPaciente: "",
    NASS2: "",

    email: "",
    password: "",
    idCliente: "",
    pueblos: [],
    todosDestinos: [],
    todosCentros: [],
    clientes: [],
    pacientes: [],
    idClienteSol: null,
    perfilLog: null,
    urgente: false,
    peritoneal: false,
  });

  const [activeStep, setActiveStep] = React.useState(0);

  const nextStep = () => {
    if (
      activeStep == 0 &&
      (stateI.tipo == "" ||
        stateI.destino.title == "" ||
        Object.keys(stateI.destino).length === 0 ||
        stateI.hora == "" ||
        stateI.horaString == "" ||
        stateI.direccionHospital == "" ||
        stateI.fechaCompletaEnviar == "" ||
        stateI.idClienteSol == null)
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Llena todos los datos por favor",
      });
    } else if (
      activeStep == 1 &&
      (stateI.NASS1 == "" ||
        stateI.primerPaciente == "" ||
        (typeof stateI.puebloPrimerPaciente === "object" &&
          Object.keys(stateI.puebloPrimerPaciente).length === 0))
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Llena todos los datos por favor",
      });
    } else {
      const { step } = stateI;
      setStateI({
        ...stateI,
        step: step + 1,
      });
      setActiveStep(step + 1);
    }
  };

  const prevStep = () => {
    const { step } = stateI;
    setStateI({
      ...stateI,
      step: step - 1,
    });
    setActiveStep(step - 1);
  };
  //handle fecha
  const handleConfirm = (date, tipo) => {
    //Dia semana
    let dia = moment(date).day();
    // console.warn("El dia de la semana es: " + dia);
    console.warn("A date has been picked: ", date);
    //Fecha elegida
    const diaNum = date.getDate();
    const mesNum = date.getMonth() + 1;
    const yearNum = date.getFullYear();
    const horasNum = date.getHours();
    const minutos = date.getMinutes();
    //Fecha actual
    let today = new Date();
    let dd = today.getDate();
    let mm = String(today.getMonth() + 1);
    let yyyy = today.getFullYear();

    let todayFecha = moment().day();
    let fechaE = moment(date).day();

    if (tipo == "Fecha") {
      if (date >= today || todayFecha == fechaE) {
        let pruebaFecha = `${yearNum}-${mesNum}-${diaNum}`;
        let fechaMostrar = pruebaFecha.split(" ");
        let dias = fechaMostrar[0].split("-");
        let fechaInput = `El ${moment(date).format("DD/MM/YYYY")}, falta hora`;

        setStateI({
          ...stateI,
          fecha: date,
          fechaMostrar: fechaInput,
          fechaCompletaEnviar: pruebaFecha,
          horaString: "",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No puedes elegir una fecha pasada, escoge una correcta por favor",
        });
      }
    } else {
      let horaCompleta = `${horasNum}:${minutos}:00`;
      let fechaInput = `El ${moment(values.fecha).format(
        "DD/MM/YYYY"
      )} a las ${moment(date).format("HH:mm")}`;
      console.log(moment(stateI.fecha).format("DD/MM/YYYY"));
      setStateI({
        ...stateI,
        hora: date,
        horaString: horaCompleta,
        fechaCompletaEnviar: `${moment(stateI.fecha).format(
          "YYYY-MM-DD"
        )} ${horaCompleta}`,
        fechaMostrar: fechaInput,
      });
    }
  };

  const handleVehiculo = (value) => {
    setStateI({
      ...stateI,
      tipo: value,
    });
  };

  const handlePueblo1 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        puebloPrimerPaciente: {},
      });
    } else if (value.title != undefined) {
      setStateI({
        ...stateI,
        puebloPrimerPaciente: value,
      });
    } else {
      setStateI({
        ...stateI,
        puebloPrimerPaciente: { title: value },
      });
    }
  };

  const handlePueblo2 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        puebloSegundoPaciente: {},
      });
    } else if (value.title != undefined) {
      setStateI({
        ...stateI,
        puebloSegundoPaciente: value,
      });
    } else {
      setStateI({
        ...stateI,
        puebloSegundoPaciente: { title: value },
      });
    }
  };

  const handleDestinoViaje = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        destiono: {},
      });
    } else if (value.title != undefined) {
      setStateI({
        ...stateI,
        destino: value,
      });
    } else {
      setStateI({
        ...stateI,
        destino: { title: value },
      });
    }
  };
  const handleCentros = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        direccionHospital: {},
      });
    } else if (value.title != undefined) {
      setStateI({
        ...stateI,
        direccionHospital: value,
      });
    } else {
      setStateI({
        ...stateI,
        direccionHospital: { title: value },
      });
    }
  };
  const handleData = (input) => (e) => {
    setStateI({
      ...stateI,
      [input]: e.target.value,
    });
  };
  const handleClientes = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        idClienteSol: null,
      });
    } else if (value != undefined) {
      setStateI({
        ...stateI,
        idClienteSol: value,
      });
    } else {
      setStateI({
        ...stateI,
        idClienteSol: value,
      });
    }
  };
  const handlePacientes1 = (value) => {
    console.log(value);
    if (value == null) {
      setStateI({
        ...stateI,
        primerPaciente: "",
        puebloPrimerPaciente: {},
        telfPrimerPaciente: "",
        direccionPrimerPaciente: "",
        NASS1: "",
        telfPrimerPaciente2: "",
      });
    } else if (value != undefined) {
      setStateI({
        ...stateI,
        primerPaciente: value.nombre,
        puebloPrimerPaciente: { title: value.pueblo },
        telfPrimerPaciente: value.numTelefono,
        direccionPrimerPaciente: value.direccion,
        NASS1: value.nass,
        telfPrimerPaciente2: value.numTelefono2,
      });
    } else {
      setStateI({
        ...stateI,
        primerPaciente: value.nombre,
        puebloPrimerPaciente: { title: value.pueblo },
        telfPrimerPaciente: value.numTelefono,
        direccionPrimerPaciente: value.direccion,
        NASS1: value.nass,
        telfPrimerPaciente2: value.numTelefono2,
      });
    }
  };
  const handlePacientes2 = (value) => {
    // console.log(value);
    if (value == null) {
      setStateI({
        ...stateI,
        segundoPaciente: "",
        puebloSegundoPaciente: {},
        telfSegundoPaciente: "",
        direccionSegundoPaciente: "",
        NASS2: "",
        telfSegundoPaciente2: "",
      });
    } else if (value != undefined) {
      setStateI({
        ...stateI,
        segundoPaciente: value.nombre,
        puebloSegundoPaciente: { title: value.pueblo },
        telfSegundoPaciente: value.numTelefono,
        direccionSegundoPaciente: value.direccion,
        NASS2: value.nass,
        telfSegundoPaciente2: value.numTelefono2,
      });
    } else {
      setStateI({
        ...stateI,
        segundoPaciente: value.nombre,
        puebloSegundoPaciente: { title: value.pueblo },
        telfSegundoPaciente: value.numTelefono,
        direccionSegundoPaciente: value.direccion,
        NASS2: value.nass,
        telfSegundoPaciente2: value.numTelefono2,
      });
    }
  };
  const handleUrgente = (value) => {
    console.log(value);
    if (value != null) {
      setStateI({
        ...stateI,
        urgente: value,
      });
    }
  };
  const handlePeritoneal = (value) => {
    console.log(value);
    if (value != null) {
      setStateI({
        ...stateI,
        peritoneal: value,
      });
    }
  };

  //Handle fields on change
  const {
    step,
    tipo,
    destino,
    direccionHospital,
    fecha,
    hora,
    fechaCompletaEnviar,
    fechaString,
    horaString,
    fechaMostrar,

    primerPaciente,
    puebloPrimerPaciente,
    telfPrimerPaciente,
    telfPrimerPaciente2,
    direccionPrimerPaciente,
    NASS1,

    segundoPaciente,
    puebloSegundoPaciente,
    telfSegundoPaciente,
    telfSegundoPaciente2,
    direccionSegundoPaciente,
    NASS2,

    email,
    password,
    idCliente,
    pueblos,
    todosDestinos,
    todosCentros,
    clientes,
    idClienteSol,
    perfilLog,
    pacientes,
    urgente,
    peritoneal,
  } = stateI;

  const values = {
    step,
    tipo,
    destino,
    direccionHospital,
    fecha,
    hora,
    fechaCompletaEnviar,
    fechaString,
    horaString,
    fechaMostrar,

    primerPaciente,
    puebloPrimerPaciente,
    telfPrimerPaciente,
    telfPrimerPaciente2,

    direccionPrimerPaciente,
    NASS1,

    segundoPaciente,
    puebloSegundoPaciente,
    telfSegundoPaciente,
    telfSegundoPaciente2,
    direccionSegundoPaciente,
    NASS2,

    email,
    password,
    idCliente,
    pueblos,

    todosDestinos,
    todosCentros,
    clientes,
    idClienteSol,
    perfilLog,
    pacientes,
    urgente,
    peritoneal,
  };
  // console.log(stateI);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar>
          <Link href="/" color="inherit">
            <IconButton edge="start" aria-label="menu">
              <ArrowBackIcon color="inherit" />
            </IconButton>
          </Link>
          <Typography variant="h6" color="inherit" noWrap>
            Pedir servicio de quimioterapia
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Box borderRadius={16} borderColor="primary.main" {...defaultProps}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Pedir quimioterapia
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
            </React.Fragment>
          </Paper>
        </Box>
      </main>
    </React.Fragment>
  );
}
