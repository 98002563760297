import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
// import InboxIcon from "@material-ui/icons/MoveToInbox";
// import MailIcon from "@material-ui/icons/Mail";
import ListSubheader from "@material-ui/core/ListSubheader";
// import { Link } from "react-router-dom";
//Icons
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
// import DriveEtaIcon from "@material-ui/icons/DriveEta";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import HistoryIcon from "@material-ui/icons/History";
import HomeIcon from "@material-ui/icons/Home";
// import ErrorIcon from "@material-ui/icons/Error";
import LiveHelpIcon from "@material-ui/icons/LiveHelp";
// import InfoIcon from "@material-ui/icons/Info";
//Home

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerContainer: {
    overflow: "auto",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

let perfil = localStorage.getItem("perfil");
export default function ClippedDrawer() {
  const classes = useStyles();

  const renderMenu = (index, text) => {
    if (index === 0) {
      return (
        <ListItem button key={text} component="a" href="/">
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      );
    } else if (index === 1) {
      return (
        <ListItem button key={text} component="a" href="/viajes-solicitados">
          <ListItemIcon>
            <HistoryIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      );
    } else if (index === 2) {
      return (
        <ListItem
          button
          key={text}
          component="a"
          href="mailto:dyurax@gmail.com"
        >
          <ListItemIcon>
            <LiveHelpIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      );
    }
  };

  const renderServicios = (text) => {
    if (text === "Diálisis") {
      return (
        <ListItem button key={text} component="a" href="/dialisis">
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      );
    } else if (text === "Radioterapia") {
      return (
        <ListItem button key={text} component="a" href="/radioterapia">
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      );
    } else if (text === "Quimioterapia") {
      return (
        <ListItem button key={text} component="a" href="/quimioterapia">
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      );
    } else if (text === "Mutuas") {
      return (
        <ListItem button key={text} component="a" href="/mutuas">
          <ListItemIcon>
            <LocalHospitalIcon />
          </ListItemIcon>
          <ListItemText primary={text} />
        </ListItem>
      );
    }
  };

  const signOut = () => {
    localStorage.removeItem("idCliente");
    localStorage.removeItem("email");
    localStorage.removeItem("password");
    localStorage.removeItem("perfil");
    localStorage.removeItem("csrfToken");
    window.location.reload();
  };

  const opciones = ["Diálisis", "Radioterapia", "Quimioterapia", "Mutuas"];
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" noWrap>
            ASSOCIACIÓ PROVINCIAL D'AUTO TAXIS - LLEIDA
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div className={classes.drawerContainer}>
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Opciones principales
              </ListSubheader>
            }
            component="nav"
          >
            {["Home", "Viajes solicitados", "Enviar email a soporte"].map(
              (text, index) => renderMenu(index, text)
            )}
          </List>
          <Divider />
          <List
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                Solicitud de servicios
              </ListSubheader>
            }
            component="nav"
          >
            {/* {[
              "Diálisis",
              "Radioterapia",
              "Quimioterapia",
              "Mutuas",

              {
                /* "Servicio de taxi", */
            /* }, */
            /* ].map((text, index) => renderServicios(text))} */}
            <div>
              {Number(perfil) === 5
                ? opciones.map((text, index) => renderServicios(text))
                : opciones
                    .slice(0, 3)
                    .map((text, index) => renderServicios(text))}
            </div>
          </List>
          <Divider />
          {/* <List component="nav">
            <ListItem
              button
              key={"Contácta a Vigsoft"}
              component="a"
              href="mailto:ferrantclk@gmail.com"
            >
              <ListItemIcon>
                <InfoIcon />
              </ListItemIcon>
              <ListItemText primary={"Contácta a Vigsoft"} />
            </ListItem>
          </List> */}
          {/* <Divider /> */}
          <List>
            {["Cerrar sesión"].map((text, index) => (
              <ListItem button key={text} onClick={() => signOut()}>
                <ListItemIcon>
                  <ExitToAppIcon></ExitToAppIcon>
                </ListItemIcon>
                <ListItemText primary={text} />
              </ListItem>
            ))}
          </List>
        </div>
      </Drawer>
    </div>
  );
}
