import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Dialisis from "./components/Servicios/Dialisis/Dialisis";
import Radioterapia from "./components/Servicios/Radioterapia/Radioterapia";
import Quimioterapia from "./components/Servicios/Quimioterapia/Quimioterapia";
import Mutuas from "./components/Servicios/Mutuas/Mutuas";

import Login from "./components/Login/Login";
import ViajesSolicitados from "./components/ViajesSolicitados";
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import Home from "./components/Home";
import Quejas from "./components/Quejas";
import Splash from "./Splash";
import AcercaDe from "./components/AcercaDe";
import { BASE_URL } from "./utils/index";
import { Redirect } from "react-router-dom";

function App() {
  const [isLogged, setLogged] = useState(false);
  const [loading, setLoading] = useState(true);
  let perfil = localStorage.getItem("perfil");

  useEffect(() => {
    const fetchData = async () => {
      let email2 = localStorage.getItem("email");
      let password2 = localStorage.getItem("password");
      let idCliente2 = localStorage.getItem("idCliente");
      let csrfToken = localStorage.getItem("csrfToken");
      console.log(csrfToken);

      let response = await fetch(`${BASE_URL}/home`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": csrfToken, // Adjuntar el token CSRF como encabezado
        },
        body: JSON.stringify({
          email: email2,
          password: password2,
          idCliente: idCliente2,
        }),
      });

      let responseServer = await response.json();
      // console.log(responseServer);
      if (
        responseServer.message ===
          "Authorization Token not found, you do not have permission to see this data" ||
        email2 === "" ||
        email2 === null
      ) {
        localStorage.removeItem("idCliente");
        localStorage.removeItem("email");
        localStorage.removeItem("password");
        localStorage.removeItem("csrfToken");
        localStorage.removeItem("perfil");
        setLoading(false);
        // window.location.reload();
      } else {
        setLoading(false);
        setLogged(true);
      }
    };

    fetchData();
  }, []);
  console.log(isLogged);
  // Create a client
  const queryClient = new QueryClient();

  if (loading) {
    return <Splash />;
  }

  if (!isLogged) {
    return <Login />;
  } else {
    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <Dashboard />
        <BrowserRouter>
          <Switch>
            <Route path="/" exact={true}>
              <Home />
            </Route>
            <Route path="/dialisis">
              <Dialisis />
            </Route>
            <Route path="/radioterapia">
              <Radioterapia />
            </Route>
            <Route path="/quimioterapia">
              <Quimioterapia />
            </Route>
            {Number(perfil) === 5 ? (
              <Route path="/mutuas">
                <Mutuas />
              </Route>
            ) : (
              <Redirect to="/" />
            )}
            {/* <Route path="/servicio-taxi">
              <Lleida />
            </Route>
            <Route path="/viaje-barcelona">
              <Barcelona />
            </Route> */}
            <Route path="/viajes-solicitados">
              <ViajesSolicitados />
            </Route>
            <Route path="/quejas">
              <Quejas />
            </Route>
            <Route path="/acerca-de">
              <AcercaDe />
            </Route>
          </Switch>
        </BrowserRouter>
      </QueryClientProvider>
    );
  }
}

export default App;
