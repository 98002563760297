import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// import Grid from "@material-ui/core/Grid";
import { Divider } from "@material-ui/core";

import Button from "@material-ui/core/Button";
// const products = [
//   { name: "Product 1", desc: "A nice thing", price: "$9.99" },
//   { name: "Product 2", desc: "Another thing", price: "$3.45" },
//   { name: "Product 3", desc: "Something else", price: "$6.51" },
//   { name: "Product 4", desc: "Best thing of all", price: "$14.11" },
//   { name: "Shipping", desc: "", price: "Free" },
// ];
// const addresses = [
//   "1 Material-UI Drive",
//   "Reactville",
//   "Anytown",
//   "99999",
//   "USA",
// ];
// const payments = [
//   { name: "Card type", detail: "Visa" },
//   { name: "Card holder", detail: "Mr John Smith" },
//   { name: "Card number", detail: "xxxx-xxxx-xxxx-1234" },
//   { name: "Expiry date", detail: "04/2024" },
// ];

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: theme.spacing(1, 0),
  },
  total: {
    fontWeight: 700,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

export default function Confirmacion(props) {
  const classes = useStyles();
  console.log(props.values);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Tus datos para la Mutua
      </Typography>
      <List disablePadding>
        {Number(props.values.perfilLog) === 5 ? (
          <ListItem className={classes.listItem}>
            <ListItemText primary={"Cliente Solicitante"} />
            <Typography variant="body2">
              {props.values.idClienteSol.nombre}
            </Typography>
          </ListItem>
        ) : null}
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Identificador IDA"} />
          <Typography variant="body2">
            {props.values.identificadorIda}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Identificador Vuelta"} />
          <Typography variant="body2">
            {props.values.identificadorVuelta}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Tipo de vehículo"} />
          <Typography variant="body2">{props.values.tipo}</Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Cantidad de sesiones"} />
          <Typography variant="body2">{props.values.sesiones}</Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Centro de asistencia"} />
          <Typography variant="body2">
            {props.values.direccionHospital.title}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Destino"} />
          <Typography variant="body2">{props.values.destino.title}</Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Direccion Destino"} />
          <Typography variant="body2">
            {props.values.direccionDestino}
          </Typography>
        </ListItem>
        {props.values.destino1 ? (
          <>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Destino 1 adicional"} />
              <Typography variant="body2">
                {props.values.destino1Destino.title}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Direccion Destino 1 adicional"} />
              <Typography variant="body2">
                {props.values.direccionDestino}
              </Typography>
            </ListItem>
          </>
        ) : null}
        {props.values.destino2 ? (
          <>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Destino 2 adicional"} />
              <Typography variant="body2">
                {props.values.destino2Destino.title}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Direccion Destino 2 adicional"} />
              <Typography variant="body2">
                {props.values.destino2direccionDestino}
              </Typography>
            </ListItem>
          </>
        ) : null}
        {props.values.destino3 ? (
          <>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Destino 3 adicional"} />
              <Typography variant="body2">
                {props.values.destino3Destino.title}
              </Typography>
            </ListItem>
            <ListItem className={classes.listItem}>
              <ListItemText primary={"Direccion Destino 3 adicional"} />
              <Typography variant="body2">
                {props.values.destino3direccionDestino}
              </Typography>
            </ListItem>
          </>
        ) : null}

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Fecha de inicio del tratamiento"} />
          <Typography variant="body2">{props.values.fechaMostrar}</Typography>
        </ListItem>
        {props.values.mananaTarde ? (
          <ListItem className={classes.listItem}>
            <ListItemText primary={"Fecha de inicio del tratamiento Tarde"} />
            <Typography variant="body2">
              {props.values.fechaMostrarTarde}
            </Typography>
          </ListItem>
        ) : null}
        <Divider variant="middle" />

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Primer Paciente"} />
          <Typography variant="body2">{props.values.primerPaciente}</Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Pueblo del primer paciente"} />
          <Typography variant="body2">
            {props.values.puebloPrimerPaciente.title}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Dirección del primer paciente"} />
          <Typography variant="body2">
            {props.values.direccionPrimerPaciente}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono del primer paciente"} />
          <Typography variant="body2">
            {props.values.telfPrimerPaciente}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono alternativo del primer paciente"} />
          <Typography variant="body2">
            {props.values.telfPrimerPaciente2
              ? props.values.telfPrimerPaciente2
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"N.A.S.S - CIP del primer paciente"} />
          <Typography variant="body2">{props.values.NASS1}</Typography>
        </ListItem>
        <Divider variant="middle" />
        {/* Segundo paciente */}
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Segundo Paciente"} />
          <Typography variant="body2">
            {props.values.segundoPaciente ? props.values.segundoPaciente : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Pueblo del segundo paciente"} />
          <Typography variant="body2">
            {props.values.puebloSegundoPaciente.title
              ? props.values.puebloSegundoPaciente.title
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Dirección del segundo paciente"} />
          <Typography variant="body2">
            {props.values.direccionSegundoPaciente
              ? props.values.direccionSegundoPaciente
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono del Segundo paciente"} />
          <Typography variant="body2">
            {props.values.telfSegundoPaciente
              ? props.values.telfSegundoPaciente
              : "NO"}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono alternativo del Segundo paciente"} />
          <Typography variant="body2">
            {props.values.telfSegundoPaciente2
              ? props.values.telfSegundoPaciente2
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"N.A.S.S - CIP del segundo paciente"} />
          <Typography variant="body2">
            {props.values.NASS2 ? props.values.NASS2 : "NO"}
          </Typography>
        </ListItem>
        <Divider variant="middle" />

        {/* Tercer paciente */}
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Tercer Paciente"} />
          <Typography variant="body2">
            {props.values.tercerPaciente ? props.values.tercerPaciente : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Pueblo del Tercer paciente"} />
          <Typography variant="body2">
            {props.values.puebloTercerPaciente.title
              ? props.values.puebloTercerPaciente.title
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Dirección del Tercer paciente"} />
          <Typography variant="body2">
            {props.values.direccionTercerPaciente
              ? props.values.direccionTercerPaciente
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono del Tercer paciente"} />
          <Typography variant="body2">
            {props.values.telfTercerPaciente
              ? props.values.telfTercerPaciente
              : "NO"}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono alternativo del Tercer paciente"} />
          <Typography variant="body2">
            {props.values.telfTercerPaciente2
              ? props.values.telfTercerPaciente2
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"N.A.S.S - CIP del Tercer paciente"} />
          <Typography variant="body2">
            {props.values.NASS3 ? props.values.NASS3 : "NO"}
          </Typography>
        </ListItem>
        <Divider variant="middle" />

        {/* Cuarto paciente */}
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Cuarto Paciente"} />
          <Typography variant="body2">
            {props.values.cuartoPaciente ? props.values.cuartoPaciente : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Pueblo del Cuarto paciente"} />
          <Typography variant="body2">
            {props.values.puebloCuartoPaciente.title
              ? props.values.puebloCuartoPaciente.title
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Dirección del Cuarto paciente"} />
          <Typography variant="body2">
            {props.values.direccionCuartoPaciente
              ? props.values.direccionCuartoPaciente
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono del Cuarto paciente"} />
          <Typography variant="body2">
            {props.values.telfCuartoPaciente
              ? props.values.telfCuartoPaciente
              : "NO"}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono alternativo del Cuarto paciente"} />
          <Typography variant="body2">
            {props.values.telfCuartoPaciente2
              ? props.values.telfCuartoPaciente2
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"N.A.S.S - CIP del Cuarto paciente"} />
          <Typography variant="body2">
            {props.values.NASS4 ? props.values.NASS4 : "NO"}
          </Typography>
        </ListItem>
        <Divider variant="middle" />

        {/* Quinto paciente */}
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Quinto Paciente"} />
          <Typography variant="body2">
            {props.values.quintoPaciente ? props.values.quintoPaciente : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Pueblo del Quinto paciente"} />
          <Typography variant="body2">
            {props.values.puebloQuintoPaciente.title
              ? props.values.puebloQuintoPaciente.title
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Dirección del Quinto paciente"} />
          <Typography variant="body2">
            {props.values.direccionQuintoPaciente
              ? props.values.direccionQuintoPaciente
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono del Quinto paciente"} />
          <Typography variant="body2">
            {props.values.telfQuintoPaciente
              ? props.values.telfQuintoPaciente
              : "NO"}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono alternativo del Quinto paciente"} />
          <Typography variant="body2">
            {props.values.telfQuintoPaciente2
              ? props.values.telfQuintoPaciente2
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"N.A.S.S - CIP del Quinto paciente"} />
          <Typography variant="body2">
            {props.values.NASS5 ? props.values.NASS5 : "NO"}
          </Typography>
        </ListItem>
        <Divider variant="middle" />

        {/* Sexto paciente */}
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Sexto Paciente"} />
          <Typography variant="body2">
            {props.values.sextoPaciente ? props.values.sextoPaciente : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Pueblo del Sexto paciente"} />
          <Typography variant="body2">
            {props.values.puebloSextoPaciente.title
              ? props.values.puebloSextoPaciente.title
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Dirección del Sexto paciente"} />
          <Typography variant="body2">
            {props.values.direccionSextoPaciente
              ? props.values.direccionSextoPaciente
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono del Sexto paciente"} />
          <Typography variant="body2">
            {props.values.telfSextoPaciente
              ? props.values.telfSextoPaciente
              : "NO"}
          </Typography>
        </ListItem>
        <ListItem className={classes.listItem}>
          <ListItemText primary={"Teléfono alternativo del Sexto paciente"} />
          <Typography variant="body2">
            {props.values.telfSextoPaciente2
              ? props.values.telfSextoPaciente2
              : "NO"}
          </Typography>
        </ListItem>

        <ListItem className={classes.listItem}>
          <ListItemText primary={"N.A.S.S - CIP del Sexto paciente"} />
          <Typography variant="body2">
            {props.values.NASS6 ? props.values.NASS6 : "NO"}
          </Typography>
        </ListItem>
      </List>
      <div className={classes.buttons}>
        {props.activeStep !== 0 && (
          <Button onClick={props.prevStep} className={classes.button}>
            Regresar
          </Button>
        )}
        <Button
          variant="contained"
          color="primary"
          onClick={props.nextStep}
          className={classes.button}
        >
          Solicitar viaje
        </Button>
      </div>
    </React.Fragment>
  );
}
