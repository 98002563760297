import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Link from "@material-ui/core/Link";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Box from "@material-ui/core/Box";
//Screens
// import Detalles from "./ViajeDetalles";
import PrimerPaciente from "./PrimerPaciente";
import SegundoPaciente from "./SegundoPaciente";
import Confirmacion from "./Confirmacion";
import ViajeDetalles from "./ViajeDetalles";
import LoadingServicio from "./LoadingServicio";
import moment from "moment";
// import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import Swal from "sweetalert2";
import { BASE_URL } from "../../../utils/index";
import { format } from "date-fns";
import TercerPaciente from "./TercerPaciente";
import CuartoPaciente from "./CuartoPaciente";
import QuintoPaciente from "./QuintoPaciente";
import SextoPaciente from "./SextoPaciente";

// import Exitoso from "../Exitoso";
// import Error from "../Error";

export default function Mutuas() {
  const defaultProps = {
    bgcolor: "background.paper",
    m: 1,
    border: 1,
  };

  //request data

  useEffect(() => {
    const fetchMyAPI = async () => {
      let fechaInicial = new Date();
      let fechaPoner = moment(fechaInicial).format("YYYY/MM/DD");

      let email2 = localStorage.getItem("email");
      let password2 = localStorage.getItem("password");
      let idCliente = localStorage.getItem("idCliente");
      let perfil = localStorage.getItem("perfil");
      let csrfToken = localStorage.getItem("csrfToken");
      console.log(csrfToken);

      let response = await fetch(`${BASE_URL}/serviciosMutuas`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          "X-CSRF-TOKEN": csrfToken, // Adjuntar el token CSRF como encabezado
        },
        body: JSON.stringify({
          email: email2,
          password: password2,
        }),
      });

      let response2 = await response.json();
      const pueblosObj = response2.pueblos.map((e) => ({ title: e.pueblo }));
      const destinosObj = response2.destinos.map((e) => ({ title: e.destino }));
      const centrosAssistenciaObj = response2.centrosAsistencia.map((e) => ({
        title: e.label,
      }));
      const clientesObj = response2.clientes.map((e) => ({
        id: e.id,
        nombre: e.nombre,
      }));
      console.log(response2);

      const pacientesObj = response2.pacientes.map((e) => ({
        id: e.id,
        nombre: e.nombre,
        pueblo: e.pueblo,
        direccion: e.direccion,
        numTelefono: e.numTelefono,
        nass: e.nass,
        numTelefono2: e.numTelefono2,
        coordenadas: e.coordenadas,
      }));

      // console.log(response2);
      setStateI({
        ...stateI,
        todosDestinos: destinosObj,
        pueblos: pueblosObj,
        turnosLLeida: response2.cap,
        turnosArnau: response2.arnau,
        turnosIgualada: response2.igualada,
        email: email2,
        password: password2,
        idCliente: idCliente,
        fechaCompletaEnviar: fechaPoner,
        todosCentros: centrosAssistenciaObj,
        clientes: clientesObj,
        pacientes: pacientesObj,
        perfilLog: perfil,
        idClienteSol: Number(perfil) === 5 ? null : "",
      });
    };

    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Estilos
  const useStyles = makeStyles((theme) => ({
    appBar: {
      position: "relative",
    },
    layout: {
      width: "auto",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(950 + theme.spacing(2) * 2)]: {
        width: 950,
        marginLeft: "auto",
        marginRight: "auto",
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.up(950 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
    buttons: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      marginTop: theme.spacing(3),
      marginLeft: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  //Steps
  const steps = [
    "Información del viaje",
    "Primer paciente",
    "Segundo paciente*",
    "Tercer paciente*",
    "Cuarto paciente*",
    "Quinto paciente*",
    "Sexto paciente*",
    "Confirma tu viaje",
  ];

  //step counter
  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <ViajeDetalles
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            handleData={handleData}
            handleDataDireccion={handleDataDireccion}
            handleDataCoordenadas={handleDataCoordenadas}
            handleDestinoViaje1={handleDestinoViaje1}
            handleDestinoViaje2={handleDestinoViaje2}
            handleDestinoViaje3={handleDestinoViaje3}
            handleVehiculo={handleVehiculo}
            handleDestino={handleDestinoViaje}
            handleConfirm={handleConfirm}
            handleConfirmTarde={handleConfirmTarde}
            handleCentros={handleCentros}
            activeStep={activeStep}
            handleClientes={Number(perfilLog) === 5 ? handleClientes : null}
            handleUrgente={handleUrgente}
            handleAlta={handleAlta}
            handleIngreso={handleIngreso}
            handleMananaTarde={handleMananaTarde}
            handleDateSelect={handleDateSelect}
            handleDestino1={handleDestino1}
            handleDestino2={handleDestino2}
            handleDestino3={handleDestino3}
            // handleIndentificadorIda={handleIndentificadorIda}
            // handleIndentificadorVuelta={handleIndentificadorVuelta}
            selectedDates={selectedDates}
          />
        );

      case 1:
        return (
          <PrimerPaciente
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            handleData={handleData}
            handleDataDireccion={handleDataDireccion}
            handleDataCoordenadas={handleDataCoordenadas}
            handlePueblo1={handlePueblo1}
            activeStep={activeStep}
            handlePacientes={handlePacientes1}
          />
        );

      case 2:
        return (
          <SegundoPaciente
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            handleData={handleData}
            handleDataDireccion={handleDataDireccion}
            handleDataCoordenadas={handleDataCoordenadas}
            handlePueblo={handlePueblo2}
            activeStep={activeStep}
            handlePacientes={handlePacientes2}
          />
        );
      case 3:
        return (
          <TercerPaciente
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            handleData={handleData}
            handleDataDireccion={handleDataDireccion}
            handleDataCoordenadas={handleDataCoordenadas}
            handlePueblo={handlePueblo3}
            activeStep={activeStep}
            handlePacientes={handlePacientes3}
          />
        );
      case 4:
        return (
          <CuartoPaciente
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            handleData={handleData}
            handleDataDireccion={handleDataDireccion}
            handleDataCoordenadas={handleDataCoordenadas}
            handlePueblo={handlePueblo4}
            activeStep={activeStep}
            handlePacientes={handlePacientes4}
          />
        );
      case 5:
        return (
          <QuintoPaciente
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            handleData={handleData}
            handleDataDireccion={handleDataDireccion}
            handleDataCoordenadas={handleDataCoordenadas}
            handlePueblo={handlePueblo5}
            activeStep={activeStep}
            handlePacientes={handlePacientes5}
          />
        );
      case 6:
        return (
          <SextoPaciente
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            handleData={handleData}
            handleDataDireccion={handleDataDireccion}
            handleDataCoordenadas={handleDataCoordenadas}
            handlePueblo={handlePueblo6}
            activeStep={activeStep}
            handlePacientes={handlePacientes6}
          />
        );

      case 7:
        return (
          <Confirmacion
            nextStep={nextStep}
            prevStep={prevStep}
            values={values}
            activeStep={activeStep}
          />
        );

      case 8:
        return <LoadingServicio values={values} />;

      default:
        break;
    }
  }

  //Estado global
  const [stateI, setStateI] = useState({
    step: 0,
    tipo: "TURISME",
    destino: {},
    direccionDestino: "",
    direccionHospital: "",
    sesiones: 1,
    fecha: new Date(),
    hora: new Date(),
    fechaCompletaEnviar: "",
    fechaString: "",
    horaString: "",
    fechaMostrar: "Aún no seleccionas fecha",
    fechaTarde: new Date(),
    horaTarde: new Date(),
    fechaCompletaEnviarTarde: "",
    fechaStringTarde: "",
    horaStringTarde: "",
    fechaMostrarTarde: "Aún no seleccionas fecha",

    primerPaciente: "",
    puebloPrimerPaciente: {},
    telfPrimerPaciente: "",
    telfPrimerPaciente2: "",
    direccionPrimerPaciente: "",
    NASS1: "",

    segundoPaciente: "",
    puebloSegundoPaciente: {},
    telfSegundoPaciente: "",
    telfSegundoPaciente2: "",
    direccionSegundoPaciente: "",
    NASS2: "",

    tercerPaciente: "",
    puebloTercerPaciente: {},
    telfTercerPaciente: "",
    telfTercerPaciente2: "",
    direccionTercerPaciente: "",
    NASS3: "",

    cuartoPaciente: "",
    puebloCuartoPaciente: {},
    telfCuartoPaciente: "",
    telfCuartoPaciente2: "",
    direccionCuartoPaciente: "",
    NASS4: "",

    quintoPaciente: "",
    puebloQuintoPaciente: {},
    telfQuintoPaciente: "",
    telfQuintoPaciente2: "",
    direccionQuintoPaciente: "",
    NASS5: "",

    sextoPaciente: "",
    puebloSextoPaciente: {},
    telfSextoPaciente: "",
    telfSextoPaciente2: "",
    direccionSextoPaciente: "",
    NASS6: "",

    email: "",
    password: "",
    idCliente: "",
    pueblos: [],
    todosDestinos: [],
    todosCentros: [],
    clientes: [],
    pacientes: [],
    idPaciente: "",
    idPaciente2: "",
    idPaciente3: "",
    idPaciente4: "",
    idPaciente5: "",
    idPaciente6: "",
    identificadorIda: "",
    identificadorVuelta: "",
    idClienteSol: null,
    perfilLog: null,
    urgente: false,
    mananaTarde: false,
    coordenadasP1: "",
    coordenadasP2: "",
    coordenadasP3: "",
    coordenadasP4: "",
    coordenadasP5: "",
    coordenadasP6: "",
    coordenadasDestino: "",
    vacioAlta: false,
    vacioVuelta: false,
    destino1: false,
    destino1Destino: "",
    destino1direccionDestino: "",
    destino1coordenadasDestino: "",
    destino2: false,
    destino2Destino: "",
    destino2direccionDestino: "",
    destino2coordenadasDestino: "",
    destino3: false,
    destino3Destino: "",
    destino3direccionDestino: "",
    destino3coordenadasDestino: "",
  });
  const [selectedDates, setSelectedDates] = useState([]);

  const [activeStep, setActiveStep] = React.useState(0);

  const nextStep = () => {
    // console.log(stateI);
    if (
      Number(activeStep) === 0 &&
      (stateI.tipo === "" ||
        stateI.destino.title === "" ||
        Object.keys(stateI.destino).length === 0 ||
        // stateI.destino.title == null ||
        Number(stateI.sesiones) === 0 ||
        stateI.horaString === "" ||
        stateI.direccionHospital === "" ||
        stateI.fechaCompletaEnviar === "" ||
        stateI.identificadorIda === "" ||
        stateI.coordenadasDestino === "" ||
        stateI.idClienteSol === null)
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Llena todos los datos por favor",
      });
    } else if (
      Number(activeStep) === 1 &&
      (stateI.NASS1 === "" ||
        stateI.primerPaciente === "" ||
        (typeof stateI.puebloPrimerPaciente == "object" &&
          Object.keys(stateI.puebloPrimerPaciente).length === 0) ||
        stateI.coordenadasP1 === "")
    ) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Llena todos los datos por favor",
      });
    } else {
      const { step } = stateI;
      setStateI({
        ...stateI,
        step: step + 1,
      });
      setActiveStep(step + 1);
    }
  };

  const prevStep = () => {
    const { step } = stateI;
    setStateI({
      ...stateI,
      step: step - 1,
    });
    setActiveStep(step - 1);
  };
  //handle fecha
  const handleConfirm = (date, tipo) => {
    //Dia semana
    // let dia = moment(date).day();
    // console.warn("El dia de la semana es: " + dia);
    // console.warn("A date has been picked: ", date);
    //Fecha elegida
    const diaNum = date.getDate();
    const mesNum = date.getMonth() + 1;
    const yearNum = date.getFullYear();
    const horasNum = date.getHours();
    const minutos = date.getMinutes();
    //Fecha actual
    let today = new Date();
    // let dd = today.getDate();
    // let mm = String(today.getMonth() + 1);
    // let yyyy = today.getFullYear();

    let todayFecha = moment().day();
    let fechaE = moment(date).day();

    if (tipo === "Fecha") {
      if (date >= today || todayFecha === fechaE) {
        let pruebaFecha = `${yearNum}-${mesNum}-${diaNum}`;
        // let fechaMostrar = pruebaFecha.split(" ");
        // let diasn = fechaMostrar[0].split("-");
        let fechaInput = `El ${moment(date).format("DD/MM/YYYY")}, falta hora`;

        setStateI({
          ...stateI,
          fecha: date,
          fechaMostrar: fechaInput,
          fechaCompletaEnviar: pruebaFecha,
          horaString: "",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No puedes elegir una fecha pasada, escoge una correcta por favor",
        });
      }
    } else {
      let horaCompleta = `${horasNum}:${minutos}:00`;
      let fechaInput = `El ${moment(values.fecha).format(
        "DD/MM/YYYY"
      )} a las ${moment(date).format("HH:mm")}`;

      setStateI({
        ...stateI,
        hora: date,
        fechaCompletaEnviar: `${moment(stateI.fecha).format(
          "YYYY-MM-DD"
        )} ${horaCompleta}`,
        fechaMostrar: fechaInput,
        horaString: horaCompleta,
      });
    }
  };
  const handleConfirmTarde = (date, tipo) => {
    //Dia semana
    // let dia = moment(date).day();
    // console.warn("El dia de la semana es: " + dia);
    // console.warn("A date has been picked: ", date);
    //Fecha elegida
    const diaNum = date.getDate();
    const mesNum = date.getMonth() + 1;
    const yearNum = date.getFullYear();
    const horasNum = date.getHours();
    const minutos = date.getMinutes();
    //Fecha actual
    let today = new Date();
    // let dd = today.getDate();
    // let mm = String(today.getMonth() + 1);
    // let yyyy = today.getFullYear();

    let todayFecha = moment().day();
    let fechaE = moment(date).day();

    if (tipo === "Fecha") {
      if (date >= today || todayFecha === fechaE) {
        let pruebaFecha = `${yearNum}-${mesNum}-${diaNum}`;
        // let fechaMostrar = pruebaFecha.split(" ");
        // let diasn = fechaMostrar[0].split("-");
        let fechaInput = `El ${moment(date).format("DD/MM/YYYY")}, falta hora`;

        setStateI({
          ...stateI,
          fechaTarde: date,
          fechaMostrarTarde: fechaInput,
          fechaCompletaEnviarTarde: pruebaFecha,
          horaStringTarde: "",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "No puedes elegir una fecha pasada, escoge una correcta por favor",
        });
      }
    } else {
      let horaCompleta = `${horasNum}:${minutos}:00`;
      let fechaInput = `El ${moment(values.fecha).format(
        "DD/MM/YYYY"
      )} a las ${moment(date).format("HH:mm")}`;

      setStateI({
        ...stateI,
        horaTarde: date,
        fechaCompletaEnviarTarde: `${moment(stateI.fecha).format(
          "YYYY-MM-DD"
        )} ${horaCompleta}`,
        fechaMostrarTarde: fechaInput,
        horaStringTarde: horaCompleta,
      });
    }
  };
  const handleDateSelect = (date) => {
    console.log(date);
    if (date !== null) {
      if (date instanceof Date) {
        const formattedDate = format(date, "yyyy-MM-dd");

        setSelectedDates((prevDates) => {
          if (prevDates.includes(formattedDate)) {
            return prevDates.filter((d) => d !== formattedDate);
          } else {
            return [...prevDates, formattedDate];
          }
        });
      } else {
        console.error("Fecha no válida:", date);
      }
    }
  };
  const handleVehiculo = (value) => {
    setStateI({
      ...stateI,
      tipo: value,
    });
  };
  const handlePueblo1 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        puebloPrimerPaciente: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        puebloPrimerPaciente: value,
      });
    } else {
      setStateI({
        ...stateI,
        puebloPrimerPaciente: { title: value },
      });
    }
  };
  const handlePueblo2 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        puebloSegundoPaciente: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        puebloSegundoPaciente: value,
      });
    } else {
      setStateI({
        ...stateI,
        puebloSegundoPaciente: { title: value },
      });
    }
  };
  const handlePueblo3 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        puebloTercerPaciente: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        puebloTercerPaciente: value,
      });
    } else {
      setStateI({
        ...stateI,
        puebloTercerPaciente: { title: value },
      });
    }
  };
  const handlePueblo4 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        puebloCuartoPaciente: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        puebloCuartoPaciente: value,
      });
    } else {
      setStateI({
        ...stateI,
        puebloCuartoPaciente: { title: value },
      });
    }
  };
  const handlePueblo5 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        puebloQuintoPaciente: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        puebloQuintoPaciente: value,
      });
    } else {
      setStateI({
        ...stateI,
        puebloQuintoPaciente: { title: value },
      });
    }
  };
  const handlePueblo6 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        puebloSextoPaciente: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        puebloSextoPaciente: value,
      });
    } else {
      setStateI({
        ...stateI,
        puebloSextoPaciente: { title: value },
      });
    }
  };
  const handleDestinoViaje = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        destiono: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        destino: value,
      });
    } else {
      setStateI({
        ...stateI,
        destino: { title: value },
      });
    }
  };
  const handleCentros = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        direccionHospital: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        direccionHospital: value,
      });
    } else {
      setStateI({
        ...stateI,
        direccionHospital: { title: value },
      });
    }
  };
  const handleData = (input) => (e) => {
    // const value = e?.target?.value || e; // Si `e` no tiene `target.value`, toma `e` directamente
    setStateI((prevState) => ({
      ...prevState,
      [input]: e?.target?.value,
    }));
  };
  const handleDestinoViaje1 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        destino1Destino: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        destino1Destino: value,
      });
    } else {
      setStateI({
        ...stateI,
        destino1Destino: { title: value },
      });
    }
  };
  const handleDestinoViaje2 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        destino2Destino: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        destino2Destino: value,
      });
    } else {
      setStateI({
        ...stateI,
        destino2Destino: { title: value },
      });
    }
  };
  const handleDestinoViaje3 = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        destino3Destino: {},
      });
    } else if (value.title !== undefined) {
      setStateI({
        ...stateI,
        destino3Destino: value,
      });
    } else {
      setStateI({
        ...stateI,
        destino3Destino: { title: value },
      });
    }
  };
  const handleDataCoordenadas = (input) => (e) => {
    const value = e?.target?.value || e; // Si `e` no tiene `target.value`, toma `e` directamente
    setStateI((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };
  const handleDataDireccion = (input) => (e) => {
    const value = e?.target?.value || e; // Si `e` no tiene `target.value`, toma `e` directamente
    setStateI((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };
  const handleClientes = (value) => {
    if (value == null) {
      setStateI({
        ...stateI,
        idClienteSol: null,
      });
    } else if (value !== undefined) {
      setStateI({
        ...stateI,
        idClienteSol: value,
      });
    } else {
      setStateI({
        ...stateI,
        idClienteSol: value,
      });
    }
  };
  const handlePacientes1 = (value) => {
    console.log(value);
    if (value == null) {
      setStateI({
        ...stateI,
        idPaciente: "",
        primerPaciente: "",
        puebloPrimerPaciente: {},
        telfPrimerPaciente: "",
        direccionPrimerPaciente: "",
        NASS1: "",
        telfPrimerPaciente2: "",
        coordenadasP1: "",
      });
    } else if (value !== undefined) {
      setStateI({
        ...stateI,
        idPaciente: value.id,
        primerPaciente: value.nombre,
        puebloPrimerPaciente: { title: value.pueblo },
        telfPrimerPaciente: value.numTelefono,
        direccionPrimerPaciente: value.direccion,
        NASS1: value.nass,
        telfPrimerPaciente2: value.numTelefono2,
        coordenadasP1: value.coordenadas,
      });
    } else {
      setStateI({
        ...stateI,
        idPaciente: value.id,
        primerPaciente: value.nombre,
        puebloPrimerPaciente: { title: value.pueblo },
        telfPrimerPaciente: value.numTelefono,
        direccionPrimerPaciente: value.direccion,
        NASS1: value.nass,
        telfPrimerPaciente2: value.numTelefono2,
        coordenadasP1: value.coordenadas,
      });
    }
  };
  const handlePacientes2 = (value) => {
    console.log(value);
    if (value == null) {
      setStateI({
        ...stateI,
        idPaciente2: "",
        segundoPaciente: "",
        puebloSegundoPaciente: {},
        telfSegundoPaciente: "",
        direccionSegundoPaciente: "",
        NASS2: "",
        telfSegundoPaciente2: "",
        coordenadasP2: "",
      });
    } else if (value !== undefined) {
      setStateI({
        ...stateI,
        idPaciente2: value.id,
        segundoPaciente: value.nombre,
        puebloSegundoPaciente: { title: value.pueblo },
        telfSegundoPaciente: value.numTelefono,
        direccionSegundoPaciente: value.direccion,
        NASS2: value.nass,
        telfSegundoPaciente2: value.numTelefono2,
        coordenadasP2: value.coordenadas,
      });
    } else {
      setStateI({
        ...stateI,
        idPaciente2: value.id,
        segundoPaciente: value.nombre,
        puebloSegundoPaciente: { title: value.pueblo },
        telfSegundoPaciente: value.numTelefono,
        direccionSegundoPaciente: value.direccion,
        NASS2: value.nass,
        telfSegundoPaciente2: value.numTelefono2,
        coordenadasP2: value.coordenadas,
      });
    }
  };
  const handlePacientes3 = (value) => {
    console.log(value);
    if (value == null) {
      setStateI({
        ...stateI,
        idPaciente3: "",
        tercerPaciente: "",
        puebloTercerPaciente: {},
        telfTercerPaciente: "",
        direccionTercerPaciente: "",
        NASS3: "",
        telfTercerPaciente2: "",
        coordenadasP3: "",
      });
    } else if (value !== undefined) {
      setStateI({
        ...stateI,
        idPaciente3: value.id,
        tercerPaciente: value.nombre,
        puebloTercerPaciente: { title: value.pueblo },
        telfTercerPaciente: value.numTelefono,
        direccionTercerPaciente: value.direccion,
        NASS3: value.nass,
        telfTercerPaciente2: value.numTelefono2,
        coordenadasP3: value.coordenadas,
      });
    } else {
      setStateI({
        ...stateI,
        idPaciente3: value.id,
        tercerPaciente: value.nombre,
        puebloTercerPaciente: { title: value.pueblo },
        telfTercerPaciente: value.numTelefono,
        direccionTercerPaciente: value.direccion,
        NASS3: value.nass,
        telfTercerPaciente2: value.numTelefono2,
        coordenadasP3: value.coordenadas,
      });
    }
  };
  const handlePacientes4 = (value) => {
    console.log(value);
    if (value == null) {
      setStateI({
        ...stateI,
        idPaciente4: "",
        cuartoPaciente: "",
        puebloCuartoPaciente: {},
        telfCuartoPaciente: "",
        direccionCuartoPaciente: "",
        NASS4: "",
        telfCuartoPaciente2: "",
        coordenadasP4: "",
      });
    } else if (value !== undefined) {
      setStateI({
        ...stateI,
        idPaciente4: value.id,
        cuartoPaciente: value.nombre,
        puebloCuartoPaciente: { title: value.pueblo },
        telfCuartoPaciente: value.numTelefono,
        direccionCuartoPaciente: value.direccion,
        NASS4: value.nass,
        telfCuartoPaciente2: value.numTelefono2,
        coordenadasP4: value.coordenadas,
      });
    } else {
      setStateI({
        ...stateI,
        idPaciente4: value.id,
        cuartoPaciente: value.nombre,
        puebloCuartoPaciente: { title: value.pueblo },
        telfCuartoPaciente: value.numTelefono,
        direccionCuartoPaciente: value.direccion,
        NASS4: value.nass,
        telfCuartoPaciente2: value.numTelefono2,
        coordenadasP4: value.coordenadas,
      });
    }
  };
  const handlePacientes5 = (value) => {
    console.log(value);
    if (value == null) {
      setStateI({
        ...stateI,
        idPaciente5: "",
        quintoPaciente: "",
        puebloQuintoPaciente: {},
        telfQuintoPaciente: "",
        direccionQuintoPaciente: "",
        NASS5: "",
        telfQuintoPaciente2: "",
        coordenadasP5: "",
      });
    } else if (value !== undefined) {
      setStateI({
        ...stateI,
        idPaciente5: value.id,
        quintoPaciente: value.nombre,
        puebloQuintoPaciente: { title: value.pueblo },
        telfQuintoPaciente: value.numTelefono,
        direccionQuintoPaciente: value.direccion,
        NASS5: value.nass,
        telfQuintoPaciente2: value.numTelefono2,
        coordenadasP5: value.coordenadas,
      });
    } else {
      setStateI({
        ...stateI,
        idPaciente5: value.id,
        quintoPaciente: value.nombre,
        puebloQuintoPaciente: { title: value.pueblo },
        telfQuintoPaciente: value.numTelefono,
        direccionQuintoPaciente: value.direccion,
        NASS5: value.nass,
        telfQuintoPaciente2: value.numTelefono2,
        coordenadasP5: value.coordenadas,
      });
    }
  };
  const handlePacientes6 = (value) => {
    console.log(value);
    if (value == null) {
      setStateI({
        ...stateI,
        idPaciente6: "",
        sextoPaciente: "",
        puebloSextoPaciente: {},
        telfSextoPaciente: "",
        direccionSextoPaciente: "",
        NASS6: "",
        telfSextoPaciente2: "",
        coordenadasP6: "",
      });
    } else if (value !== undefined) {
      setStateI({
        ...stateI,
        idPaciente6: value.id,
        sextoPaciente: value.nombre,
        puebloSextoPaciente: { title: value.pueblo },
        telfSextoPaciente: value.numTelefono,
        direccionSextoPaciente: value.direccion,
        NASS6: value.nass,
        telfSextoPaciente2: value.numTelefono2,
        coordenadasP6: value.coordenadas,
      });
    } else {
      setStateI({
        ...stateI,
        idPaciente6: value.id,
        sextoPaciente: value.nombre,
        puebloSextoPaciente: { title: value.pueblo },
        telfSextoPaciente: value.numTelefono,
        direccionSextoPaciente: value.direccion,
        NASS6: value.nass,
        telfSextoPaciente2: value.numTelefono2,
        coordenadasP6: value.coordenadas,
      });
    }
  };
  const handleUrgente = (value) => {
    console.log(value);
    if (value != null) {
      setStateI({
        ...stateI,
        urgente: value,
      });
    }
  };
  const handleAlta = (value) => {
    console.log(value);
    if (value != null) {
      setStateI({
        ...stateI,
        vacioAlta: value,
      });
    }
  };
  const handleIngreso = (value) => {
    console.log(value);
    if (value != null) {
      setStateI({
        ...stateI,
        vacioVuelta: value,
      });
    }
  };
  const handleMananaTarde = (value) => {
    console.log(value);
    if (value != null) {
      setStateI({
        ...stateI,
        mananaTarde: value,
      });
    }
  };
  const handleDestino1 = (value) => {
    console.log(value);
    if (value != null) {
      setStateI({
        ...stateI,
        destino1: value,
      });
    }
  };
  const handleDestino2 = (value) => {
    console.log(value);
    if (value != null) {
      setStateI({
        ...stateI,
        destino2: value,
      });
    }
  };
  const handleDestino3 = (value) => {
    console.log(value);
    if (value != null) {
      setStateI({
        ...stateI,
        destino3: value,
      });
    }
  };
  //Handle fields on change
  const {
    step,
    tipo,
    destino,
    direccionHospital,
    sesiones,
    fecha,
    hora,
    fechaCompletaEnviar,
    fechaString,
    horaString,
    fechaMostrar,
    fechaTarde,
    horaTarde,
    fechaCompletaEnviarTarde,
    fechaStringTarde,
    horaStringTarde,
    fechaMostrarTarde,

    primerPaciente,
    puebloPrimerPaciente,
    telfPrimerPaciente,
    telfPrimerPaciente2,
    direccionPrimerPaciente,
    NASS1,

    segundoPaciente,
    puebloSegundoPaciente,
    telfSegundoPaciente,
    telfSegundoPaciente2,
    direccionSegundoPaciente,
    NASS2,

    tercerPaciente,
    puebloTercerPaciente,
    telfTercerPaciente,
    telfTercerPaciente2,
    direccionTercerPaciente,
    NASS3,

    cuartoPaciente,
    puebloCuartoPaciente,
    telfCuartoPaciente,
    telfCuartoPaciente2,
    direccionCuartoPaciente,
    NASS4,

    quintoPaciente,
    puebloQuintoPaciente,
    telfQuintoPaciente,
    telfQuintoPaciente2,
    direccionQuintoPaciente,
    NASS5,

    sextoPaciente,
    puebloSextoPaciente,
    telfSextoPaciente,
    telfSextoPaciente2,
    direccionSextoPaciente,
    NASS6,

    email,
    password,
    idCliente,
    pueblos,
    todosDestinos,
    todosCentros,
    clientes,
    idClienteSol,
    perfilLog,
    pacientes,
    idPaciente,
    idPaciente2,
    idPaciente3,
    idPaciente4,
    idPaciente5,
    idPaciente6,
    urgente,
    mananaTarde,
    identificadorIda,
    identificadorVuelta,
    direccionDestino,
    coordenadasP1,
    coordenadasP2,
    coordenadasP3,
    coordenadasP4,
    coordenadasP5,
    coordenadasP6,
    coordenadasDestino,
    vacioAlta,
    vacioVuelta,
    destino1,
    destino1Destino,
    destino1direccionDestino,
    destino1coordenadasDestino,
    destino2,
    destino2Destino,
    destino2direccionDestino,
    destino2coordenadasDestino,
    destino3,
    destino3Destino,
    destino3direccionDestino,
    destino3coordenadasDestino,
  } = stateI;

  const values = {
    step,
    tipo,
    destino,
    sesiones,
    direccionHospital,
    fecha,
    hora,
    fechaCompletaEnviar,
    fechaString,
    horaString,
    fechaMostrar,
    fechaTarde,
    horaTarde,
    fechaCompletaEnviarTarde,
    fechaStringTarde,
    horaStringTarde,
    fechaMostrarTarde,

    primerPaciente,
    puebloPrimerPaciente,
    telfPrimerPaciente,
    telfPrimerPaciente2,
    direccionPrimerPaciente,
    NASS1,

    segundoPaciente,
    puebloSegundoPaciente,
    telfSegundoPaciente,
    telfSegundoPaciente2,
    direccionSegundoPaciente,
    NASS2,

    tercerPaciente,
    puebloTercerPaciente,
    telfTercerPaciente,
    telfTercerPaciente2,
    direccionTercerPaciente,
    NASS3,

    cuartoPaciente,
    puebloCuartoPaciente,
    telfCuartoPaciente,
    telfCuartoPaciente2,
    direccionCuartoPaciente,
    NASS4,

    quintoPaciente,
    puebloQuintoPaciente,
    telfQuintoPaciente,
    telfQuintoPaciente2,
    direccionQuintoPaciente,
    NASS5,

    sextoPaciente,
    puebloSextoPaciente,
    telfSextoPaciente,
    telfSextoPaciente2,
    direccionSextoPaciente,
    NASS6,

    email,
    password,
    idCliente,
    pueblos,

    todosDestinos,
    todosCentros,
    clientes,
    idClienteSol,
    perfilLog,
    pacientes,
    idPaciente,
    idPaciente2,
    idPaciente3,
    idPaciente4,
    idPaciente5,
    idPaciente6,
    urgente,
    mananaTarde,
    identificadorIda,
    identificadorVuelta,
    direccionDestino,
    coordenadasP1,
    coordenadasP2,
    coordenadasP3,
    coordenadasP4,
    coordenadasP5,
    coordenadasP6,
    coordenadasDestino,
    vacioAlta,
    vacioVuelta,
    destino1,
    destino1Destino,
    destino1direccionDestino,
    destino1coordenadasDestino,
    destino2,
    destino2Destino,
    destino2direccionDestino,
    destino2coordenadasDestino,
    destino3,
    destino3Destino,
    destino3direccionDestino,
    destino3coordenadasDestino,
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar position="fixed" color="default" className={classes.appBar}>
        <Toolbar>
          <Link href="/" color="inherit">
            <IconButton edge="start" aria-label="menu">
              <ArrowBackIcon color="inherit" />
            </IconButton>
          </Link>
          <Typography variant="h6" color="inherit" noWrap>
            Pedir servició de Mutuas
          </Typography>
        </Toolbar>
      </AppBar>
      <main className={classes.layout}>
        <Box borderRadius={16} borderColor="primary.main" {...defaultProps}>
          <Paper className={classes.paper}>
            <Typography component="h1" variant="h4" align="center">
              Pedir servició de Mutuas
            </Typography>
            <Stepper activeStep={activeStep} className={classes.stepper}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <React.Fragment>
              <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
            </React.Fragment>
          </Paper>
        </Box>
      </main>
    </React.Fragment>
  );
}
